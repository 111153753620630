import { UilStar } from '@iconscout/react-unicons';

import { Toggle } from '@/components/ui/toggle';

import { useGetCurrentUser } from '@/hooks/user';

import { USER_TYPE } from '@/services/user';

interface IFavoriteButtonProps {
  isFavorite: boolean;
  onClick: () => void;
  className?: string;
}

const UilStarFilled = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3332 6.05772C17.2806 5.90529 17.1849 5.77141 17.0577 5.67231C16.9305 5.5732 16.7772 5.51313 16.6166 5.49939L11.8749 4.80772L9.7499 0.499389C9.68166 0.358496 9.57512 0.239674 9.44247 0.156533C9.30983 0.0733927 9.15645 0.0292969 8.9999 0.0292969C8.84335 0.0292969 8.68997 0.0733927 8.55733 0.156533C8.42468 0.239674 8.31814 0.358496 8.2499 0.499389L6.1249 4.79939L1.38323 5.49939C1.229 5.52131 1.084 5.58602 0.964692 5.68619C0.84538 5.78635 0.756533 5.91795 0.708233 6.06606C0.664021 6.21078 0.660053 6.36482 0.696756 6.51163C0.733459 6.65845 0.809448 6.79249 0.916567 6.89939L4.35823 10.2327L3.5249 14.9661C3.49515 15.1223 3.51073 15.2838 3.56979 15.4314C3.62886 15.5791 3.72894 15.7068 3.85823 15.7994C3.98425 15.8895 4.13288 15.9427 4.28744 15.953C4.442 15.9633 4.59638 15.9303 4.73323 15.8577L8.9999 13.6327L13.2499 15.8661C13.3669 15.932 13.499 15.9665 13.6332 15.9661C13.8098 15.9667 13.9819 15.9112 14.1249 15.8077C14.2542 15.7151 14.3543 15.5874 14.4133 15.4398C14.4724 15.2921 14.488 15.1306 14.4582 14.9744L13.6249 10.2411L17.0666 6.90772C17.1869 6.80579 17.2758 6.67181 17.323 6.52135C17.3702 6.37088 17.3738 6.21012 17.3332 6.05772ZM12.2082 9.39106C12.1105 9.48558 12.0374 9.60259 11.9953 9.73187C11.9531 9.86114 11.9433 9.99876 11.9666 10.1327L12.5666 13.6244L9.43323 11.9577C9.31266 11.8935 9.17816 11.8599 9.04157 11.8599C8.90497 11.8599 8.77047 11.8935 8.6499 11.9577L5.51657 13.6244L6.11657 10.1327C6.13985 9.99876 6.12999 9.86114 6.08787 9.73187C6.04575 9.60259 5.97263 9.48558 5.8749 9.39106L3.3749 6.89106L6.88323 6.38272C7.01823 6.36394 7.14656 6.31234 7.25698 6.23243C7.3674 6.15252 7.45653 6.04675 7.51657 5.92439L8.9999 2.74939L10.5666 5.93272C10.6266 6.05509 10.7157 6.16086 10.8262 6.24077C10.9366 6.32067 11.0649 6.37228 11.1999 6.39106L14.7082 6.89939L12.2082 9.39106Z"
      fill="#FFDA53"
    />
    <path
      d="M12.2082 9.39106C12.1105 9.48558 12.0374 9.60259 11.9953 9.73187C11.9531 9.86114 11.9433 9.99876 11.9666 10.1327L12.5666 13.6244L9.43323 11.9577C9.31266 11.8935 9.17816 11.8599 9.04157 11.8599C8.90497 11.8599 8.77047 11.8935 8.6499 11.9577L5.51657 13.6244L6.11657 10.1327C6.13985 9.99876 6.12999 9.86114 6.08787 9.73187C6.04575 9.60259 5.97263 9.48558 5.8749 9.39106L3.3749 6.89106L6.88323 6.38272C7.01823 6.36394 7.14656 6.31234 7.25698 6.23243C7.3674 6.15252 7.45653 6.04675 7.51657 5.92439L8.9999 2.74939L10.5666 5.93272C10.6266 6.05509 10.7157 6.16086 10.8262 6.24077C10.9366 6.32067 11.0649 6.37228 11.1999 6.39106L14.7082 6.89939L12.2082 9.39106Z"
      fill="#FFDA53"
    />
  </svg>
);

const FavoriteButton: React.FC<IFavoriteButtonProps> = ({ onClick, isFavorite = false }) => {
  const { data: user } = useGetCurrentUser({});

  return (
    <Toggle
      pressed={isFavorite}
      onPressedChange={onClick}
      className="hover:bg-transparent disabled:opacity-100"
      disabled={user?.user_type !== USER_TYPE.BUSINESS} // Move logic to parent if required
    >
      {isFavorite ? <UilStarFilled /> : <UilStar className="size-5 text-primary-dark-60" />}
    </Toggle>
  );
};

export default FavoriteButton;
