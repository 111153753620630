import React, { useRef, useState } from 'react';
import { UilFileAlt } from '@iconscout/react-unicons';
import { useDropzone } from 'react-dropzone';
import Cropper, { Area } from 'react-easy-crop';

import { useS3 } from '@/hooks/s3';

import getCroppedImgBlob from '@/utils/cropImage';

import { cn } from '@/lib/utils';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './ui/alert-dialog';
import { Button } from './ui/button';
import { Label } from './ui/label';
import { Slider } from './ui/slider';

export interface CoverImageUploaderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  acceptedFileTypes?: string[];
  keyPrefix: string;
  onFileUpload: (fileUrl: string, fileName: string) => void;
  className?: string;
  currentFile?: string | null;
  formError?: string;
}

const convertArrayToObject = (arr: string[]): { [key: string]: [] } => {
  return arr.reduce(
    (acc, curr) => {
      acc[curr] = [];
      return acc;
    },
    {} as { [key: string]: [] }
  );
};

export const CoverImageUploader: React.FC<CoverImageUploaderProps> = ({
  acceptedFileTypes = ['image/jpeg', 'image/png'],
  keyPrefix,
  onFileUpload,
  className,
  currentFile,
  formError,
  ...props
}) => {
  const { upload } = useS3();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [imgName, setImgName] = useState<string>('');
  // const validateImageSize = (file: File): Promise<boolean> => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       if (e.target?.result) {
  //         img.src = e.target.result as string;
  //         img.onload = () => {
  //           if (img.width === 1080 && img.height === 320) {
  //             resolve(true);
  //           } else {
  //             reject('Image must be of the size 1080px by 320px.');
  //           }
  //         };
  //       }
  //     };

  //     reader.onerror = () => {
  //       reject('Error reading the image file.');
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  const handleUpload = async (file: File) => {
    setLoading(true);
    setError(null);
    try {
      // const isValidSize = await validateImageSize(file);
      // if (!isValidSize) {
      //   return;
      // }

      const key = `${keyPrefix}/${file.name}`;
      const url = await upload(file, key);
      onFileUpload(url, file.name);
    } catch (err) {
      setError(typeof err === 'string' ? err : 'Upload failed. Please try again.');
    } finally {
      setLoading(false);
      setOpen(false);
      reset();
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result as string);
        setImgName(acceptedFiles[0].name);
        setOpen(true);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: convertArrayToObject(acceptedFileTypes),
    multiple: false,
  });

  const openFileExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const reset = () => {
    setImgSrc(null);
    setImgName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="min-h-40 w-full">
      <input
        {...getInputProps()}
        {...props}
        ref={fileInputRef}
      />
      {!currentFile && (
        <div
          {...getRootProps({
            className: cn(
              'inline-flex h-full w-full items-center justify-center gap-4 rounded-lg border border-dashed border-primary-blue-40 bg-white px-4 py-8',
              isDragActive ? 'border-dashed border-primary-blue-40' : '',
              (!!error || formError) && 'border-primary-alert-100 ring-4 ring-primary-alert-10',
              className
            ),
          })}
          onClick={openFileExplorer}
        >
          <div className="relative h-8 w-8">
            {loading && (
              <div className="spinner-border inline-block h-8 w-8 animate-spin rounded-full border-4 text-blue-600"></div>
            )}
          </div>
          <div>
            {isDragActive ? (
              <span className="text-lg font-semibold leading-7 text-primary-dark-100">Drop the file here...</span>
            ) : (
              <div className="flex flex-col items-center gap-4">
                <UilFileAlt className="size-8 text-primary-dark-60" />
                <p className="text-lg font-semibold leading-7 text-primary-dark-100">
                  Drag & drop your Cover Image or{' '}
                  <span className="cursor-pointer text-lg font-semibold leading-7 text-blue-600">upload</span>
                </p>
                <br />
                <span className="text-xs font-medium text-primary-dark-60">
                  Upload a JPEG or PNG file • 1080px - 320px
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {currentFile && !loading && (
        <Uploaded
          isViewOnly={props.disabled}
          currentFile={currentFile}
          onClick={openFileExplorer}
        />
      )}
      {(error || formError) && (
        <div className="mt-2 text-sm font-medium leading-tight text-primary-alert-100">{error || formError}</div>
      )}

      <ImageCropper
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            reset();
          }
          setOpen(open);
        }}
        originalImgSrc={imgSrc}
        handleUpload={handleUpload}
        imgName={imgName}
        isLoading={loading}
      />
    </div>
  );
};

function Uploaded({
  currentFile,
  onClick,
  isViewOnly,
}: {
  currentFile: string;
  onClick: () => void;
  isViewOnly?: boolean;
}) {
  return (
    <div className="relative h-full w-full overflow-hidden rounded-lg border border-primary-blue-40">
      <img
        src={currentFile}
        alt="Cover Image"
        className="contain h-full w-full"
      />
      <Button
        disabled={isViewOnly}
        type="button"
        variant="tertiary"
        onClick={onClick}
        className="absolute right-4 top-4"
      >
        Change image
      </Button>
    </div>
  );
}

function ImageCropper({
  open,
  onOpenChange,
  originalImgSrc,
  imgName,
  handleUpload,
  isLoading,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  originalImgSrc: string | null;
  imgName: string;
  handleUpload: (file: File) => void;
  isLoading: boolean;
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [showCropper, setShowCropper] = useState(false);

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onUploadImage = async () => {
    if (!originalImgSrc || !croppedAreaPixels) return;
    const croppedImageBlob = await getCroppedImgBlob(originalImgSrc, croppedAreaPixels, 0);
    if (!croppedImageBlob) return;
    handleUpload(new File([croppedImageBlob], imgName, { type: 'image/jpeg' }));
  };

  React.useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
        setShowCropper(true);
      }, 150);
      return () => clearTimeout(timer);
    } else {
      setShowCropper(false);
    }
  }, [open]);

  if (!originalImgSrc) return null;

  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <AlertDialogContent className="flex max-w-3xl flex-col gap-y-8">
        <AlertDialogHeader>
          <AlertDialogTitle>Cover image</AlertDialogTitle>
          <AlertDialogDescription className="hidden">
            Adjust the image to fit the desired aspect ratio.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="relative h-[390px] w-full">
          {showCropper && (
            <Cropper
              image={originalImgSrc}
              crop={crop}
              zoom={zoom}
              aspect={4 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              objectFit="horizontal-cover"
            />
          )}
        </div>
        <div className="">
          <Label className="text-base font-medium">Zoom</Label>
          <div className="flex items-center gap-x-4">
            <span className="text-2xl font-medium text-primary-dark-60">-</span>
            <Slider
              value={[zoom]}
              onValueChange={([zoom]) => setZoom(zoom)}
              min={1}
              max={3}
              step={0.01}
            />
            <span className="text-2xl font-medium text-primary-dark-60">+</span>
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            onClick={onUploadImage}
            loading={isLoading}
            loadingText="Applying"
            disabled={isLoading}
          >
            Apply
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
