import { useState } from 'react';
import { UilEyeSlash, UilFastMail, UilLockAlt, UilTrashAlt, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

import { useGetCurrentBusinessPermissions } from '@/hooks/permission';
import useAuth from '@/hooks/useAuth';

import { USER_TYPE } from '@/services/user';

interface TeamTableActionButtonProps {
  id: string;
  email: string;
  onResetPassword: (id: string, email: string) => void;
  onResendInvite: (id: string, email: string) => void;
  onRemove: (id: string, email: string) => void;
  hideResetPassword?: boolean;
}

export function TeamTableActionButton({
  id,
  email,
  onResetPassword,
  onResendInvite,
  onRemove,
  hideResetPassword = false,
}: TeamTableActionButtonProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data: permissions } = useGetCurrentBusinessPermissions();

  const userType = user?.app_user_type;

  const domain = userType === USER_TYPE.BUSINESS ? 'business' : 'partner';

  const [isOpen, setIsOpen] = useState(false);

  const handleSelection = () => {
    setIsOpen(true);
  };

  return (
    <DropdownMenu
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton onClick={handleSelection} />
      </DropdownMenuTrigger>
      {isOpen && (
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => navigate({ to: `/${domain}/team/${id}` })}>
            <UilUserCircle className="default-icon-sm" /> View Profile
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate({ to: `/${domain}/team/${id}/permissions` })}>
            <UilLockAlt className="default-icon-sm" /> Permissions
          </DropdownMenuItem>
          {Boolean(!hideResetPassword) && (
            <DropdownMenuItem
              onClick={() => onResetPassword(id, email)}
              disabled={!permissions?.manage_team_members}
            >
              <UilEyeSlash className="default-icon-sm" /> Reset password
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => onResendInvite(id, email)}
            disabled={!permissions?.manage_team_members}
          >
            <UilFastMail className="default-icon-sm" /> Resend invite
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => onRemove(id, email)}
            disabled={!permissions?.manage_team_members}
          >
            <UilTrashAlt className="default-icon-sm" /> Remove
          </DropdownMenuItem>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
}
