import { createFileRoute } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

// import { AnalyticsCard } from '@/components/business/partner/analytics-card';
import PartnersTable from '@/components/business/partner/partners-table';

import { useGetBusinessPartners, useGetCurrentBusiness } from '@/hooks/business';
import useFilters from '@/hooks/useFilters';

import { Filters } from '@/services/types';

// import { PARTNER_ANALYTICS_TOOLTIPS } from '@/constants/partner-tooltips';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/')({
  component: Partners,
});

function Partners() {
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});
  const { filters, setFilters } = useFilters<Filters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const { data, isPending } = useGetBusinessPartners({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        sort: 'name',
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
      queryKey: [filters],
    },
  });

  return (
    <main className="space-y-6">
      <Heading
        size="page"
        title="Partners"
      />
      {/* <AnalyticsSection /> */}
      <PartnersTable
        data={data}
        filters={filters}
        setFilters={setFilters}
        paginationState={paginationState}
        isPending={isPending || isBusinessPending}
      />
    </main>
  );
}

// function AnalyticsSection() {
//   return (
//     <div className="flex w-full flex-wrap items-center justify-between gap-6">
//       <AnalyticsCard
//         title="Active roles"
//         description={PARTNER_ANALYTICS_TOOLTIPS.activeRoles}
//         // number={Math.round(Math.random() * 300)}
//       />
//       <AnalyticsCard
//         title="Hired"
//         description={PARTNER_ANALYTICS_TOOLTIPS.hired}
//         // number={Math.round(Math.random() * 300)}
//       />
//       <AnalyticsCard
//         title="Placement ratio"
//         description={PARTNER_ANALYTICS_TOOLTIPS.placementRatio}
//         // number={`${Math.round(Math.random() * 30)} : ${Math.round(Math.random() * 300)}`}
//       />
//     </div>
//   );
// }
