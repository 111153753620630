import { useState } from 'react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import CultureQuiz from '@/components/culture-quiz';
import LoadingCard from '@/components/quiz/loading';

import { useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { CultureName } from '@/services/candidate';

import { parseCultureDescription, prepareCultureQuizData } from '@/utils/string';

import { CULTURE_QUIZ_OPTIONS } from '@/constants/quiz';
import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute('/_authenticated/candidate/quiz/culture/')({
  component: CultureQuizPage,
});

function CultureQuizPage() {
  const [cultureQuizResult, setCultureQuizResult] = useState<(CultureName | null)[]>([]);

  const { data: cultureValueMap, isLoading: isCultureValueMapLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });
  const navigate = useNavigate();
  const { mutateAsync: updateCandidate, isPending: isUpdateCandidatePending } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => navigate({ to: '/candidate/quiz/culture/result' }),
    },
  });

  const parsedCultureValueMap = cultureValueMap?.items?.map((item) =>
    parseCultureDescription(item.description as string)
  );

  if (isCultureValueMapLoading) return <LoadingCard />;

  const handleSubmit = () => {
    const data = cultureQuizResult.map((name) => CULTURE_QUIZ_OPTIONS.find((o) => o.name === name));
    const length = data.length;
    const details = data.map((option, index) => {
      const culture = parsedCultureValueMap?.find((detail) => detail.name === option?.name);
      return {
        name: option?.name as CultureName,
        measures: culture?.measures || '',
        rank: index + 1,
        score: length - index,
      };
    });
    const parsed = prepareCultureQuizData(details);
    updateCandidate({ body: { culture: parsed } });
  };

  return (
    <div className="px-8">
      <section className="mx-auto flex max-w-[67.5rem] flex-col gap-6 py-10 3xl:gap-8 3xl:py-16">
        <h1 className="text-xl font-semibold leading-tight text-primary-dark-100 2xl:text-2xl 2xl:leading-normal 3xl:leading-loose">
          Considering the type of workplace where you will thrive, please rank the following workplace cultures from
          most to least suitable for you.
        </h1>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          Please click and hold to move the cards into your preferred order.
        </p>
        <div>
          <Button
            size="lg"
            onClick={handleSubmit}
            disabled={cultureQuizResult.some((trait) => trait === null)}
            loading={isUpdateCandidatePending}
            className="min-w-32"
          >
            Submit
          </Button>
        </div>
      </section>
      <main className="pb-12">
        <CultureQuiz
          onChange={setCultureQuizResult}
          className="max-w-[78rem]"
        />
      </main>
    </div>
  );
}
