import { useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { UilInfoCircle } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import { CultureChart } from '@/components/quiz/charts/culture-chart';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { TCandidateCulture } from '@/services/candidate';

import { parseCultureDescription } from '@/utils/string';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

const CultureQuizResults = () => {
  const { data, setStepData } = useCreateRoleStore();
  const { goToStep } = useCreateRoleStepper();
  const { data: cultureValueMap, isLoading: isCultureLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });
  const [showScoreBreakdown, setShowScoreBreakdown] = useState(false);

  if (isCultureLoading)
    return (
      <div className="flex h-full w-full flex-1 items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  if (!data?.cultureFactors.length) return null;

  const highestRankCultureName = data.cultureFactors[0];

  const culture = cultureValueMap?.items?.find(
    (item) => parseCultureDescription(item.description as string).name === highestRankCultureName
  )?.description as string;

  const cultureDescription = parseCultureDescription(culture);

  const cultureChartData: TCandidateCulture[] = data.cultureFactors.map((culture, index) => ({
    measures: '',
    name: culture,
    score: data?.cultureFactors.length - index,
    rank: index + 1,
  }));

  const cultureFactorsChartData = cultureValueMap?.items.map((item) => {
    if (item.description && item.code) {
      const desc = { ...parseCultureDescription(item.description), code: item.code };

      return desc;
    } else
      return {
        code: '',
        name: 'string',
        measures: 'string',
        means: 'string',
        ends: 'string',
        thrust: 'string',
        extras: [''],
      };
  });

  return (
    <Card className="space-y-14 p-8">
      <CardHeader className="flex flex-row items-start justify-between">
        <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[highestRankCultureName]}</span>
        <div className="flex gap-4">
          <Button
            variant="tertiary"
            onClick={() => setShowScoreBreakdown(!showScoreBreakdown)}
          >
            {showScoreBreakdown ? 'Hide' : 'Show'} breakdown
          </Button>
          <Button
            variant="tertiary"
            onClick={() => {
              setStepData({
                ...data,
                cultureFactors: [],
              });
              goToStep(5, '/culture-quiz');
            }}
          >
            Retake quiz
          </Button>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-6 2xl:text-lg 3xl:text-xl">
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            CULTURE
          </h3>
          {cultureDescription.name}
        </div>
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            THRUST
          </h3>
          {cultureDescription.thrust}
        </div>
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            MEANS
          </h3>
          {cultureDescription.means}
        </div>
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            ENDS
          </h3>
          {cultureDescription.ends}
        </div>
      </CardContent>
      {showScoreBreakdown && cultureFactorsChartData && (
        <CardContent className="flex flex-col gap-4">
          <h1 className="text-2xl font-semibold">Score breakdown</h1>
          <div className="flex w-max rounded-lg bg-primary-blue-5 px-2 py-1 text-base font-medium leading-normal text-primary-blue-100">
            <UilInfoCircle
              width={18}
              height={18}
              className="my-auto mr-2"
            />
            <span>Hover over the tags to find out more</span>
          </div>
          <div className="mx-auto my-4 h-full w-full max-w-[880px]">
            <CultureChart
              data={cultureChartData}
              factors={cultureFactorsChartData}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default CultureQuizResults;
