import { useState } from 'react';
import { Link } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import Avatar from '@/components/ui/avatar';
import { DataTable } from '@/components/ui/data-table';
import { Tag } from '@/components/ui/tag';

import { useGetCurrentDashboardURL } from '@/hooks/user';

import {
  TBusinessTeamMember,
  TGetBusinessTeamMembersResponse,
  TUpdateBusinessTeamMembersDetailsRequest,
} from '@/services/business';
import { Filters } from '@/services/types';

import { getAccountTypeLabel } from '@/constants/business-team';

import InviteTeamMemberButton from './invite-team-button';
import { RemoveTeamMemberButton } from './remove-member-modal';
import { ResendInviteButton } from './resend-invite-modal';
import { ResetPasswordButton } from './reset-password-modal';
import { TeamTableActionButton } from './team-table-action-button';

function getAccountType(
  isOwner: boolean,
  accountType?: NonNullable<
    TUpdateBusinessTeamMembersDetailsRequest['requestBody']
  >['content']['application/json']['account_type']
) {
  if (!accountType) return 'Unknown';
  if (isOwner) return 'Owner-Admin';
  return getAccountTypeLabel(accountType);
}

interface ITeamTableProps {
  data?: TGetBusinessTeamMembersResponse;
  isPending?: boolean;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  paginationState: PaginationState;
}

export default function TeamTable({ data, isPending, paginationState, filters, setFilters }: ITeamTableProps) {
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [resendInviteOpen, setResendInviteOpen] = useState(false);
  const [removeMemberOpen, setRemoveMemberOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<{ id: string; email: string } | null>(null);

  const dashboardUrl = useGetCurrentDashboardURL();

  const columnDefs: ColumnDef<TBusinessTeamMember>[] = [
    {
      accessorKey: 'first_name',
      id: 'first_name',
      header: 'Name',
      cell: ({ row }) => {
        return (
          <Link
            className="flex items-center gap-4"
            from={dashboardUrl}
            to={`./team/${row.original?.id}`}
          >
            <Avatar
              fallbackType="user"
              src={row.original?.image_url}
              size="md"
            />
            <span className="text-sm font-semibold">
              {row.original?.first_name} {row.original?.last_name}
            </span>
          </Link>
        );
      },
    },
    {
      header: 'Account type',
      accessorKey: 'metadata.account_type',
      id: 'metadata.account_type',
      cell: ({ row }) => {
        return (
          <Tag hideDot>
            {getAccountType(Boolean(row.original?.metadata?.is_owner), row.original?.metadata?.account_type)}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'email',
      header: 'Email address',
    },
    {
      accessorKey: 'role',
      header: 'Team',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-2">
            {row.original?.metadata?.team &&
              row.original.metadata.team.split(';').map((r) => (
                <Tag
                  key={r}
                  variant={'primary'}
                >
                  {r}
                </Tag>
              ))}
          </div>
        );
      },
    },
    // {
    //   accessorKey: 'last_active',
    //   header: 'Last active',
    //   cell: ({ row }) => {
    //     return formatDistanceToNow(new Date(row?.original?.metadata.last_active || new Date()), { addSuffix: true });
    //   },
    // },
    {
      accessorKey: 'more',
      header: '',
      size: 24,
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <TeamTableActionButton
              id={row.original.id.toString()}
              email={row.original.email}
              onResetPassword={(id, email) => {
                setSelectedMember({ id, email });
                setResetPasswordOpen(true);
              }}
              onResendInvite={(id, email) => {
                setSelectedMember({ id, email });
                setResendInviteOpen(true);
              }}
              onRemove={(id, email) => {
                setSelectedMember({ id, email });
                setRemoveMemberOpen(true);
              }}
              hideResetPassword={row.original.metadata?.user_auth_method === 'oncore-openid'} // Note: Consider other non-password-resettable auth methods
            />
          </div>
        );
      },
    },
  ];

  const handleResetPasswordSubmit = () => {
    // Handle reset password logic here
    setResetPasswordOpen(false);
  };

  const handleResendInviteSubmit = () => {
    // Handle resend invite logic here
    setResendInviteOpen(false);
  };

  const handleRemoveMemberSubmit = () => {
    // Handle remove member logic here
    setRemoveMemberOpen(false);
  };

  return (
    <>
      <DataTable
        columns={columnDefs}
        data={data?.items || []}
        title="Members"
        isLoading={isPending}
        noData={{
          title: 'No team members available',
          description: 'You currently have no team members available.',
        }}
        search={{
          value: filters.query || '',
          onChange: (value) => {
            setFilters({ ...filters, query: value });
          },
        }}
        pagination={{
          state: paginationState,
          options: {
            rowCount: data?.total || 0,
            onPaginationChange: (pagination) => {
              setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
            },
          },
        }}
        headerButtons={<InviteTeamMemberButton />}
      />
      {selectedMember && (
        <>
          <ResetPasswordButton
            id={selectedMember.id}
            email={selectedMember.email}
            hideTrigger
            triggerOpen={resetPasswordOpen}
            onOpenChange={setResetPasswordOpen}
            onSubmit={handleResetPasswordSubmit}
          />
          <ResendInviteButton
            id={selectedMember.id}
            email={selectedMember.email}
            hideTrigger
            triggerOpen={resendInviteOpen}
            onOpenChange={setResendInviteOpen}
            onSubmit={handleResendInviteSubmit}
          />
          <RemoveTeamMemberButton
            id={selectedMember.id}
            hideTrigger
            triggerOpen={removeMemberOpen}
            onOpenChange={setRemoveMemberOpen}
            onSubmit={handleRemoveMemberSubmit}
          />
        </>
      )}
    </>
  );
}
