import { Suspense } from 'react';
import { createFileRoute, Outlet, useLocation } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import FormLoader from '@/components/forms/form-loader';
import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile')({
  component: ProfileLayout,
});

interface INavItemWithSubtitle extends ITabNavItem {
  subtitle?: string;
}
const navItems: INavItemWithSubtitle[] = [
  {
    label: 'Work',
    href: '/candidate/settings/profile',
    subtitle: 'What type of work are you looking for?',
  },
  {
    label: 'Expectations',
    href: '/candidate/settings/profile/expectations',
    subtitle: 'What are your expectations?',
  },
  {
    label: 'Experience',
    href: '/candidate/settings/profile/experience',
    subtitle: 'What kind of businesses have you worked in?',
  },
  {
    label: 'Education',
    href: '/candidate/settings/profile/education',
    subtitle: 'What level of education do you have?',
  },
  {
    label: 'Profile',
    href: '/candidate/settings/profile/profile',
    subtitle: 'Profile photo and location.',
  },
  {
    label: 'Bio & Extras',
    href: '/candidate/settings/profile/bio',
    subtitle: 'Highlight your passions, skills, experiences, achievements and values. Upload your CV and add links.',
  },
  // {
  //   label: 'References',
  //   href: '/candidate/settings/profile/references',
  // },
];

const ProfileSettingsHeader = () => {
  const pathname = useLocation({
    select: (location) => location.pathname,
  });

  const currentNavItem = navItems.find((item) => item.href === pathname);

  if (!currentNavItem) return null;

  return (
    <Heading
      title={currentNavItem.label}
      subtitle={currentNavItem.subtitle}
    />
  );
};

function ProfileLayout() {
  return (
    <div>
      <Heading
        title="Profile details"
        subtitle="Update your profile details."
      />
      <TabNav
        navItems={navItems}
        variant={'underlined'}
        className="my-8 border-b border-primary-dark-10"
        triggerClassName="min-w-[136px]"
      />
      <SettingContainer>
        <ProfileSettingsHeader />
        <Card className="p-8">
          <Suspense fallback={<FormLoader />}>
            <Outlet />
          </Suspense>
        </Card>
      </SettingContainer>
    </div>
  );
}
