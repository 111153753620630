import { useState } from 'react';
import { UilPen, UilPlus } from '@iconscout/react-unicons';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useS3 } from '@/hooks/s3';

import Avatar from './avatar';
import Spinner from './spinner';

interface Props {
  value: string;
  onUpload: (url: string) => void;
  errorMessage?: string;
}
const ProfilePictureUploader: React.FC<Props> = ({ value, errorMessage, onUpload }) => {
  const [avatar, setAvatar] = useState<string | null>(value);
  const { data: candidate } = useGetCurrentCandidate({});

  const { upload, loading, error } = useS3();

  const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setAvatar(URL.createObjectURL(file));
      const url = await upload(file, `candidate/${candidate?.id}/${file.name}`);
      onUpload(url);
    }
  };

  const renderIcon = () => {
    if (loading) {
      return <Spinner size="xs" />;
    }

    if (avatar) {
      return <UilPen className="size-5 text-primary-blue-100" />;
    }

    return <UilPlus className="size-5 text-primary-blue-100" />;
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <label
        className="group relative cursor-pointer"
        htmlFor="avatar"
      >
        <Avatar
          size="4xl"
          src={avatar}
          variant="bordered"
        />
        <span className="group-hover:bg-primary-blue-500 absolute right-0 top-0 flex size-10 items-center justify-center rounded-full border-2 border-white bg-primary-blue-10 shadow-sm">
          {renderIcon()}
        </span>
      </label>
      <input
        type="file"
        id="avatar"
        accept="image/*"
        className="hidden"
        onChange={handleAvatarChange}
      />
      {(errorMessage || error) && <p className="text-sm text-red-500">{errorMessage || error?.message}</p>}
    </div>
  );
};

export default ProfilePictureUploader;
