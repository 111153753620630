import { useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/typography/heading';

import CultureQuiz from '@/components/culture-quiz';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

import { CultureName } from '@/services/candidate';

export const Route = createFileRoute('/_authenticated/business/create-role/step5/culture-quiz')({
  component: CultureQuizPage,
});

function CultureQuizPage() {
  const { skipCultureQuiz, goToStep, currentStep } = useCreateRoleStepper();
  const { data, setStepData } = useCreateRoleStore();
  const [cultureQuizResults, setCultureQuizResults] = useState<(CultureName | null)[]>([]);

  const handleSubmit = () => {
    setStepData({
      ...data,
      cultureFactors: cultureQuizResults,
    });

    goToStep(currentStep, 'results');
  };

  return (
    <main className="space-y-10 2xl:space-y-12 3xl:space-y-16">
      <header className="text-center">
        <Heading
          title="Culture quiz"
          subtitle="Please click, drag and drop each of the following traits in order 1 to 4 in terms of most to least suitable for this role."
          size="title"
        />
      </header>
      <CultureQuiz
        defaultValues={data.cultureFactors}
        onChange={setCultureQuizResults}
        className="max-w-[58.5rem]"
      />
      <footer className="flex justify-center gap-4">
        <Button
          variant="tertiary"
          className="w-[6rem]"
          onClick={skipCultureQuiz}
        >
          Skip
        </Button>
        <Button
          className="w-[6rem]"
          disabled={cultureQuizResults.some((trait) => trait === null)}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </footer>
    </main>
  );
}

export default CultureQuizPage;
