import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

import {
  BUSINESS_ROLES_ENDPOINTS,
  createJobPost,
  deleteRole,
  favouriteCandidate,
  generateRoleReferrerToken,
  TCreateJobPostRequest,
  TDeleteRoleRequest,
  TFavouriteCandidateRequest,
  TGenerateBusinessRoleReferralTokenRequest,
  TUnfavouriteCandidateRequest,
  TUpdateJobPostRequest,
  unfavouriteCandidate,
  updateJobPost,
} from '@/services/business-roles';
import { PIPELINE_ENDPOINTS, TCandidateDetails } from '@/services/pipeline';

import { UseMutationOptions } from '../types';

// import { JOB_POST_STATUS } from "@/constants/post";

export const useCreateJobPost = ({ reactQueryOptions }: UseMutationOptions<TCreateJobPostRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: createJobPost,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useUpdateJobPost = ({ reactQueryOptions }: UseMutationOptions<TUpdateJobPostRequest>) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateJobPost,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};

export const useFavouriteCandidate = ({ reactQueryOptions }: UseMutationOptions<TFavouriteCandidateRequest>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: favouriteCandidate,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};

export const useUnfavouriteCandidate = ({ reactQueryOptions }: UseMutationOptions<TUnfavouriteCandidateRequest>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: unfavouriteCandidate,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};

export const useGenerateRoleReferrerToken = ({
  reactQueryOptions,
}: UseMutationOptions<TGenerateBusinessRoleReferralTokenRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: generateRoleReferrerToken,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useDeleteRole = ({ reactQueryOptions }: UseMutationOptions<TDeleteRoleRequest>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: deleteRole,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2],
        exact: false,
        refetchType: 'all',
      });
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

// Todo: replace other favorite hook calls with this
export const useToggleFavouriteCandidate = ({ queryKey, favorite }: { queryKey: QueryKey; favorite: boolean }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: favorite ? favouriteCandidate : unfavouriteCandidate,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData = queryClient.getQueryData(queryKey) as TCandidateDetails;

      queryClient.setQueryData(queryKey, (data: TCandidateDetails) => {
        return {
          ...data,
          metadata: {
            ...data.metadata,
            favourite_yn: favorite ? 'Y' : 'N',
          },
        };
      });

      return { previousData };
    },
    onError: (_, __, context) => {
      toast.error('Error favoring role');
      if (context?.previousData) {
        queryClient.setQueryData(queryKey, context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};
