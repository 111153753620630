import { useEffect, useState } from 'react';
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { createPortal } from 'react-dom';

import DraggableCultureCard from '@/components/roles/business/create-role/quiz/draggable-culture-card';
import DroppableContainer from '@/components/roles/business/create-role/quiz/droppable-container';
import { getIndexFromId, updateTraits } from '@/components/roles/business/create-role/quiz/utils';

import { CultureName } from '@/services/candidate';

import { cn } from '@/lib/utils';

interface ICultureQuizProps {
  defaultValues?: (CultureName | null)[];
  onChange: (traits: (CultureName | null)[]) => void;
  className?: string;
}

const CultureQuiz: React.FC<ICultureQuizProps> = ({ defaultValues, onChange, className }) => {
  const [initialTraits, setInitialTraits] = useState<(CultureName | null)[]>(
    defaultValues?.length
      ? Array(4).fill(null)
      : [CultureName.CLAN, CultureName.ADHOCRACY, CultureName.MARKET, CultureName.HIERARCHY]
  );
  const [finalTraits, setFinalTraits] = useState<(CultureName | null)[]>(
    defaultValues?.length ? defaultValues : Array(4).fill(null)
  );
  const [activeCode, setActiveCode] = useState<CultureName | null>(null);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  useEffect(() => {
    onChange?.(finalTraits);
  }, [finalTraits, onChange]);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveCode(event.active.id as CultureName);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active.id || !over?.id) {
      setActiveCode(null);
      return;
    }

    const activeId = active.id as CultureName;
    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;
    const targetIndex = getIndexFromId(over.id as string);

    if (activeType === overType) {
      const traits = activeType === 'initial' ? initialTraits : finalTraits;
      const setTraits = activeType === 'initial' ? setInitialTraits : setFinalTraits;
      const currentIndex = traits.findIndex((trait) => trait === activeId);

      setTraits((prev) => {
        const newTraits = [...prev];
        [newTraits[currentIndex], newTraits[targetIndex]] = [newTraits[targetIndex], newTraits[currentIndex]];
        return newTraits;
      });
    } else {
      const sourceTraits = activeType === 'initial' ? initialTraits : finalTraits;
      const targetTraits = activeType === 'initial' ? finalTraits : initialTraits;
      const setSourceTraits = activeType === 'initial' ? setInitialTraits : setFinalTraits;
      const setTargetTraits = activeType === 'initial' ? setFinalTraits : setInitialTraits;

      if (!targetTraits[targetIndex]) {
        const currentIndex = sourceTraits.indexOf(activeId);
        setSourceTraits((prev) => updateTraits(prev, currentIndex, currentIndex, null));
        setTargetTraits((prev) => updateTraits(prev, targetIndex, targetIndex, activeId));
      }
    }

    setActiveCode(null);
  };

  const renderDragOverlay = createPortal(
    <DragOverlay zIndex={30}>{activeCode ? <DraggableCultureCard cultureName={activeCode} /> : null}</DragOverlay>,
    document.body
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <section className={cn('mx-auto flex flex-col gap-10 2xl:gap-12 3xl:gap-16', className)}>
        <div className="grid grid-cols-2 gap-5 2xl:gap-6 3xl:gap-8">
          {initialTraits.map((trait, index) => (
            <DroppableContainer
              key={index}
              id={`i${index}`}
              type="initial"
            >
              {trait && (
                <DraggableCultureCard
                  cultureName={trait}
                  type="initial"
                />
              )}
            </DroppableContainer>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-6 3xl:gap-8">
          {finalTraits.map((trait, index) => (
            <DroppableContainer
              key={index}
              id={`f${index}`}
              title={String(index + 1)}
              subtitle={index === 0 ? 'Most suitable' : index === finalTraits.length - 1 ? 'Least suitable' : ''}
              type="final"
            >
              {trait && (
                <DraggableCultureCard
                  cultureName={trait}
                  type="final"
                />
              )}
            </DroppableContainer>
          ))}
        </div>
      </section>
      {renderDragOverlay}
    </DndContext>
  );
};

export default CultureQuiz;
