import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilTimes } from '@iconscout/react-unicons';
import { AlertDialogCancel } from '@radix-ui/react-alert-dialog';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, buttonVariants } from '@/components/ui/button';
import { CardContent, CardFooter, CardTitle } from '@/components/ui/card';
import FileUploader from '@/components/ui/file-uploader-drag-and-drop';
import { Form, FormProvider } from '@/components/ui/form';
import ProfilePictureUploader from '@/components/ui/profile-picture-uploader';
import { toast } from '@/components/ui/toaster';

import FullPageLoader from '@/components/full-page-loader';

import { useGetCurrentCandidate, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetCurrentUser, useUpdateCurrentUser } from '@/hooks/user';

import { cn } from '@/lib/utils';

import { AlertDialog, AlertDialogContent } from './ui/alert-dialog';

const updateInfoSchema = z.object({
  profile_image_url: z.string().min(1, 'Please upload a profile picture'),
  cv_url: z.string().optional(),
});

type TFormValues = z.infer<typeof updateInfoSchema>;
type CV = {
  url: string;
  name: string;
};
function UpdateInfoForm({ onSuccess }: { onSuccess: () => void }) {
  const { data: user, isLoading } = useGetCurrentUser({});
  const { data: candidate, isLoading: candidateIsLoading } = useGetCurrentCandidate({});

  const { mutateAsync: updateCandidate, isPending: updateCandidateLoading } = useUpdateCurrentCandidate({});
  const { mutateAsync: updateUser, isPending: updateUserLoading } = useUpdateCurrentUser({});

  const currentCV: CV | null = candidate?.cv_url ? JSON.parse(candidate.cv_url) : null;

  const form = useForm<TFormValues>({
    values: {
      profile_image_url: user?.image_url || '',
      cv_url: candidate?.cv_url || '',
    },
    resolver: zodResolver(updateInfoSchema),
  });

  const {
    getValues,
    formState: { errors },
    handleSubmit,
    setValue,
  } = form;

  if (isLoading || candidateIsLoading) return <FullPageLoader />;

  const handleFileUpload = (url: string, name: string) => {
    const cv_url = JSON.stringify({ url, name });
    setValue('cv_url', cv_url, { shouldDirty: true });
  };

  const handleImageUpload = (url: string) => {
    setValue('profile_image_url', url, { shouldDirty: true });
  };

  const handleClearUpload = () => {
    setValue('cv_url', '', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  };

  const onSubmit = async (data: TFormValues) => {
    try {
      await updateCandidate({
        body: {
          cv_url: data.cv_url,
          image_url: data.profile_image_url,
        },
      });

      await updateUser({
        body: {
          image_url: data.profile_image_url,
        },
      });
      onSuccess();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardTitle className="flex items-center gap-3.5">
          <span className="flex-1 text-center text-2xl">Fresh year, new opportunities 🎉</span>
        </CardTitle>
        <CardContent className="space-y-6 text-primary-dark-60">
          <div className="flex flex-col items-center gap-4">
            <ProfilePictureUploader
              value={getValues('profile_image_url')}
              onUpload={handleImageUpload}
              errorMessage={errors.profile_image_url?.message}
            />
          </div>
          <div className="space-y-4 text-center">
            <h2 className="text-xl font-semibold">Hey {user?.first_name ?? 'there'}</h2>
            <p>
              It&apos;s the perfect time for a little profile glow-up! Update your details to kickstart the new year
              with a bang. Let&apos;s make 2025 your best year yet!
            </p>
            <p>Cheers to new opportunities!</p>
          </div>
          <div className="space-y-2">
            <FileUploader
              acceptedFileTypes={[
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ]}
              keyPrefix={`candidate/${candidate?.id}`}
              onFileUpload={handleFileUpload}
              currentFile={currentCV}
              label="CV"
              onClearUpload={handleClearUpload}
            />
            {errors.cv_url?.message && <p className="text-center text-sm text-red-500">{errors.cv_url?.message}</p>}
          </div>
        </CardContent>
        <CardFooter className="gap-4 pt-2">
          <Button
            type="submit"
            className="w-full flex-1"
            loading={updateCandidateLoading || updateUserLoading}
            loadingText="Updating"
          >
            Update profile
          </Button>
        </CardFooter>
      </Form>
    </FormProvider>
  );
}

const UpdateInfoModal = () => {
  const [open, setOpen] = useState(false);
  const { data: user } = useGetCurrentUser({});
  const { data: candidate } = useGetCurrentCandidate({});

  useEffect(() => {
    if (!user || !candidate) return;

    const localStorageKey = `popupDismissed_${user?.id}`;

    const popupDismissed = localStorage.getItem(localStorageKey);

    if ((!user?.image_url || !candidate?.cv_url || !candidate?.image_url) && !popupDismissed) {
      setOpen(true);
    }
  }, [user, candidate]);

  const handleOpenChange = (open: boolean) => {
    if (!user) return;

    if (!open) {
      const localStorageKey = `popupDismissed_${user?.id}`;
      localStorage.setItem(localStorageKey, 'true');
    }

    setOpen(open);
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={handleOpenChange}
    >
      <AlertDialogContent>
        <UpdateInfoForm onSuccess={() => handleOpenChange(false)} />
        <AlertDialogCancel className={cn(buttonVariants({ variant: 'ghost' }), 'absolute right-2 top-2 p-2')}>
          <UilTimes className="h-6 w-6 text-primary-dark-60" />
        </AlertDialogCancel>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateInfoModal;
