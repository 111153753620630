import { publicClient } from '@/lib/api';

import { S3_ENDPOINTS } from './s3.endpoints';

export const uploadFile = async (file: File | Blob, key: string): Promise<string> => {
  const contentType = file.type;
  try {
    const { data } = await publicClient.POST(S3_ENDPOINTS.GET_POST_PRESIGNED_URL, {
      body: {
        Key: key,
        ContentType: contentType,
      },
    });

    if (data) {
      const { data: presignedResult } = data;

      const { url, signedUrl } = presignedResult;

      const fileUploadResponse = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': contentType,
        },
      });
      if (!fileUploadResponse.ok) {
        throw new Error('Failed to upload file to S3');
      }

      return url;
    } else {
      throw new Error('Failed to prepare file to upload URL');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
