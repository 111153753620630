import { useEffect, useState } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import Avatar from '@/components/ui/avatar';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import useAuth, { isOidcUser } from '@/hooks/useAuth';
import { useGetCurrentUser } from '@/hooks/user';

import { getFullName } from '@/utils/string';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings')({
  component: SettingsLayout,
});

const Banner = () => {
  const [domNode, setDomNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const dashboardContainer = document.getElementById('dashboard-container');
    setDomNode(dashboardContainer);
  }, []);

  if (!domNode) return null;

  return createPortal(
    <div className="absolute left-0 top-0 z-0 h-[200px] w-full bg-[url('/business-profile-settings-banner.svg')]"></div>,
    domNode
  );
};

function SettingsLayout() {
  const { data: user } = useGetCurrentUser({});
  const { user: auth0User } = useAuth();
  const disablePasswordTab = !isOidcUser(auth0User || null);

  const navItems: ITabNavItem[] = [
    {
      label: 'My Details',
      href: `/business/settings`,
    },
    {
      label: 'Company',
      href: `/business/settings/company`,
      fuzzyMatch: true,
    },
    {
      label: 'Password',
      href: `/business/settings/password`,
      disabled: !disablePasswordTab,
    },
    // TODO: Add this back after notification settings is updated
    // {
    //   label: 'Notifications',
    //   href: `/business/settings/notifications`,
    // },
    {
      label: 'FAQs',
      href: `/business/settings/faqs`,
    },
  ];
  return (
    <>
      <Banner />
      <main className="relative z-10">
        <div className="mt-32 flex items-center gap-x-10">
          <Avatar
            src={user?.image_url}
            size="4xl"
            variant={'bordered'}
          />
          <h1 className="text-3xl font-semibold">{getFullName(user)}</h1>
        </div>
        <TabNav
          navItems={navItems}
          className="my-8"
        />

        <Outlet />
      </main>
    </>
  );
}
