import React, { useRef, useState } from 'react';

import { useS3 } from '@/hooks/s3';

import { cn } from '@/lib/utils';

import { Button } from './ui/button';

export interface FileUploaderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  acceptedFileTypes: string[];
  keyPrefix: string;
  onFileUpload: (fileUrl: string) => void;
  className?: string;
  formError?: boolean;
  buttonText?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  acceptedFileTypes,
  keyPrefix,
  onFileUpload,
  className,
  formError,
  buttonText,
  disabled,
  ...props
}) => {
  const { upload } = useS3();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      handleUpload(selectedFile);
    }
  };

  const handleUpload = async (file: File) => {
    try {
      setLoading(true);
      const key = `${keyPrefix}/${file.name}`;
      const url = await upload(file, key);
      onFileUpload(url);
    } catch (err) {
      console.error('Upload failed:', err);
    } finally {
      setLoading(false);
    }
  };
  const triggerFileInput = () => {
    fileInputRef?.current?.click();
  };

  return (
    <div className={cn('', className)}>
      <Button
        variant="tertiary"
        type="button"
        onClick={triggerFileInput}
        loading={loading}
        disabled={loading || disabled}
        loadingText="Uploading"
        className={cn('', formError && 'border-primary-alert-100 ring-4 ring-primary-alert-10')}
      >
        {buttonText || 'Upload Image'}
      </Button>
      <input
        type="file"
        accept={acceptedFileTypes.join(',')}
        onChange={handleFileChange}
        ref={fileInputRef}
        className="hidden"
        {...props}
      />
    </div>
  );
};

export default FileUploader;
