import { useState } from 'react';
import { UilArrowLeft, UilInfoCircle } from '@iconscout/react-unicons';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

import FeaturedIcon from '@/components/icons/featured-icon';
import { CultureChart } from '@/components/quiz/charts/culture-chart';
import CultureBreakDownCard from '@/components/quiz/culture/culture-breakdown-card';
import CultureResultCard from '@/components/quiz/culture/reuslt-card';
import LoadingCard from '@/components/quiz/loading';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import {
  CANDIDATE_ENDPOINTS,
  CultureName,
  getCurrentCandidate,
  hasCandidateCompletedCultureQuiz,
  hasCandidateCompletedPersonalityQuiz,
} from '@/services/candidate';

import { parseCandidateCulture, parseCultureDescription } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute('/_authenticated/candidate/quiz/culture/result')({
  component: CultureQuizResult,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const candidate = await queryClient.fetchQuery({
      queryFn: getCurrentCandidate,
      queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE],
      staleTime: Infinity,
    });

    if (!hasCandidateCompletedCultureQuiz(candidate)) {
      throw redirect({ to: '/candidate/quiz/culture' });
    }
  },
});

function CultureQuizResult() {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});
  const { data: cultureValueMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });
  const navigate = useNavigate();
  const [viewBreakDown, setViewBreakDown] = useState(false);

  if (isPersonalityFactorLoading || isCandidateLoading) return <LoadingCard />;

  if (!candidate?.culture) return null;

  const culture = parseCandidateCulture(candidate.culture);
  if (!culture || culture.length === 0) return null;

  const highestRankCulture = culture.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

  const candidateCultureFactor = cultureValueMap?.items?.find(
    (item) => parseCultureDescription(item.description as string).name === highestRankCulture.name
  )?.description as string;

  const candidateCulture = parseCultureDescription(candidateCultureFactor);
  const isOtherQuizCompleted = hasCandidateCompletedPersonalityQuiz(candidate);

  const cultureFactors = cultureValueMap?.items.map((item) => {
    if (item.description && item.code) {
      const desc = { ...parseCultureDescription(item.description), code: item.code };

      return desc;
    } else
      return {
        code: '',
        name: 'string',
        measures: 'string',
        means: 'string',
        ends: 'string',
        thrust: 'string',
        extras: [''],
      };
  });
  return (
    <>
      {!viewBreakDown ? (
        <div className="p-8 3xl:py-20">
          <div className="mx-auto flex max-w-[1080px] flex-col gap-8 2xl:gap-10 3xl:gap-12">
            <section className="flex flex-col gap-6 2xl:gap-8">
              <div>
                <FeaturedIcon variant="success" />
              </div>
              <div>
                <h1 className="text-2xl font-semibold text-primary-dark-100 3xl:leading-loose">
                  Well done on completing the Culture quiz!
                </h1>
                <p className="text-base font-medium leading-normal text-primary-dark-60">Let’s see how you went.</p>
              </div>
            </section>
            <main>
              <CultureResultCard
                cultureName={candidateCulture.name as CultureName}
                thrust={candidateCulture.thrust}
                means={candidateCulture.means}
                ends={candidateCulture.ends}
                onClick={() => setViewBreakDown(true)}
              />
            </main>
            <section className="flex justify-between">
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => navigate({ to: '/candidate/quiz/culture' })}
              >
                Retake the quiz
              </Button>

              <Button
                size="lg"
                onClick={() => navigate({ to: isOtherQuizCompleted ? '/candidate' : '/candidate/quiz/personality' })}
              >
                {isOtherQuizCompleted ? 'Continue' : 'Next quiz'}
              </Button>
            </section>
          </div>
        </div>
      ) : (
        // Breakdown view
        <div className="mx-auto max-w-[1980px] space-y-6 py-8 3xl:space-y-8">
          <div className="px-8">
            <Button
              size="lg"
              variant="tertiary"
              onClick={() => setViewBreakDown(false)}
            >
              <UilArrowLeft size={18} />
              Back
            </Button>
          </div>
          <main className="grid grid-cols-[2fr_4fr] justify-between gap-8 px-8 py-2">
            <div className="h-max">
              <CultureBreakDownCard
                cultureName={candidateCulture.name as CultureName}
                thrust={candidateCulture.thrust}
                means={candidateCulture.means}
                ends={candidateCulture.ends}
              />
            </div>
            <Card className="flex max-h-[56rem] min-h-[84dvh] w-full shrink-0 flex-col gap-10 rounded-2xl bg-primary-white-100 px-6 py-12 shadow-2xl">
              <section className="flex justify-between">
                <div className="flex flex-col gap-4">
                  <h1 className="text-[32px] font-semibold not-italic leading-10 text-primary-dark-100">
                    Score breakdown
                  </h1>
                  <div className="flex w-max rounded-lg bg-primary-blue-5 px-2 py-1 text-base font-medium leading-normal text-primary-blue-100">
                    <UilInfoCircle
                      width={18}
                      height={18}
                      className="my-auto mr-2"
                    />
                    <span>Hover over the tags to find out more</span>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4 text-center">
                    <div className="flex h-8 w-8 shrink-0 items-center rounded border border-primary-dark-10 p-0.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="4"
                        viewBox="0 0 24 4"
                        fill="none"
                        className="mx-auto flex"
                      >
                        <path
                          d="M0 2H24"
                          stroke="#3E60FF"
                          strokeWidth="4"
                        />
                      </svg>
                    </div>
                    <p className="text-lg font-semibold leading-[28px] text-primary-dark-100">Your results</p>
                  </div>
                </div>
              </section>
              {cultureFactors ? (
                <div className="flex flex-1 items-center justify-center">
                  <CultureChart
                    data={culture}
                    factors={cultureFactors}
                  />
                </div>
              ) : (
                <span>Cannot render graph. Personality factors not found</span>
              )}
            </Card>
          </main>
        </div>
      )}
    </>
  );
}
