import { useCallback, useState } from 'react';

import { uploadFile } from '@/services/s3/s3.services';

interface UseS3Result {
  upload: (file: File, key: string) => Promise<string>;
  loading: boolean;
  error: Error | null;
}

export const useS3 = (): UseS3Result => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const upload = useCallback(async (file: File, key: string) => {
    setLoading(true);
    setError(null);
    try {
      const result = await uploadFile(file, key);
      return result;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    upload,
    loading,
    error,
  };
};
